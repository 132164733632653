<template>
  <div class="page payChannelConfig">
    <page-header :bread-crumb="true" :back-button="true" :sync="true" />
    <div class="wrap">
      <button class="creatTG f14w400" @click="addEvent"><i class="el-icon-plus"></i>添加支付渠道</button>
      <div class="tableList">
        <!-- 表格部分 -->
        <el-table v-loading="loading" :data="tableData" style="width: 100%" class="table-box" max-height="645" :header-cell-style="{background:'#F5F5F5',color:'#606266'}">
          <el-table-column label="渠道">
            <template #default="{ row }">
              {{ getChannelName(row.channel_id) }}
            </template>
          </el-table-column>
          <el-table-column prop="channel_id" label="channel_id">
          </el-table-column>
          <el-table-column prop="pay_mode" label="pay_mode">
          </el-table-column>
          <el-table-column prop="pay_type" label="pay_type">
          </el-table-column>>
          <el-table-column prop="sourceT" label="sourceT">
          </el-table-column>
          <el-table-column prop="subject_code" label="subject_code">
          </el-table-column>
          <el-table-column prop="remark" label="备注">
          </el-table-column>
          <el-table-column  label="操作" align="center" width="120">
            <template #default="{ row }">
              <el-button
                type="text"
                style="padding: 0;color: #4D7EFA;"
                class="f14h20w400"
                @click="editEvent(row)"
              >
                编辑
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="page-box ">
          <el-pagination @current-change="handleCurrentChange" :current-page="page" :page-size="pageSize" layout="total, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogShow" :show-close="false" v-if="dialogShow" width="560px" clearable="true" :close-on-click-modal="false">
      <div class="dialog">
        <dialogHeader :title="addType == 'add'?'添加支付渠道':'编辑支付渠道'" @closeDialog="onLeave"></dialogHeader>
        <div class="dialogList">
          <el-form :rules="rules" ref="addObj" :model="addObj" label-width="130px">
            <el-form-item label="渠道：" prop="channel_id" >
              <el-select v-model="addObj.channel_id" placeholder="选择渠道" style="width: 100%;">
                <el-option v-for="(item,index) in channelList" :key="index" :label="item.channel_name" :value="item.channel_id" >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="pay_mode：" prop="pay_mode">
              <el-input v-model.trim="addObj.pay_mode"></el-input>
            </el-form-item>
            <el-form-item label="pay_type：" prop="pay_type">
              <el-input v-model.trim="addObj.pay_type"></el-input>
            </el-form-item>
            <el-form-item label="sourceT：" prop="sourceT">
              <el-input v-model.trim="addObj.sourceT"></el-input>
            </el-form-item>
            <el-form-item label="subject_code：" prop="subject_code">
              <el-input v-model.trim="addObj.subject_code"></el-input>
            </el-form-item>
            <el-form-item label="备注：" prop="remark">
              <el-input type="textarea" v-model.trim="addObj.remark"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer" flex="cross:center main:right">
          <button @click="onLeave" class="dfcancle">取 消</button>
          <button @click="onGoon('addObj')" class="dfconfirm">确 定</button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as initStore from "@/store/init.js"
import { getAccountBookConfigList, addAccountBook, editAccountBook } from "@/api/config"

export default {
  name: "payChannelConfig",
  data() {
    return {
      loading:false,
      page:1,
      pageSize:10,
      total:0,
      tableData:[],
      addType:'add',
      dialogShow:false,
      statusBox:{
        0:'启用',
        1:'禁用'
      },
      rules:{
        channel_id: [{
          required: true,
          message: "渠道不能为空",
          trigger: "blur"
        }],
        pay_mode:[{
          required: true,
          message: "pay_mode不能为空",
          trigger: "blur"
        }],
        pay_type:[{
          required: true,
          message: "pay_type不能为空",
          trigger: "blur"
        }],
        sourceT:[{
          required: true,
          message: "sourceT不能为空",
          trigger: "blur"
        }],
        subject_code:[{
          required: true,
          message: "subject_code不能为空",
          trigger: "blur"
        }],
      },
      addObj:{
        channel_id:'',        //支付渠道
        pay_mode:'',          //支付方式
        pay_type:'',          //支付类型
        sourceT:'',           //sourceT
        subject_code:'',      //账本科目
        remark:'',            //备注
      },
      selectedId:'',
      addLoading:false, //数据提交状态
    }
  },
  computed: {
    channelList: () => initStore.state.channelList,  //渠道列表
  },
  mounted() {
    this.getList();
  },
  methods: {
    getChannelName(id) {
        const channel = this.channelList.find((c) => c.channel_id == id);
        if (channel) {
            return channel.channel_name;
        }
        return "";
    },
    async getList(){
      const {page,pageSize} = this;
      let params = {
        page:page,
        page_size:pageSize
      }
      this.loading = true;
      const res = await getAccountBookConfigList(params).catch(e => e).finally(() => {this.loading = false});
      console.log(res);
      this.loading = false;
      if(res.code){
        this.tableData = res.data && res.data.list || [];
        this.total = res.data.total || 0;
      }

    },
    //page改变 促发事件
    handleCurrentChange(val){
      if(this.page == val) return;
      this.page = val;
      this.getList();
    },
    //新增事件
    addEvent(){
      this.dialogShow = true;
      this.addType = "add";
    },
    //编辑事件
    editEvent(val){
      let { id, channel_id, pay_mode, pay_type, sourceT, subject_code, remark } = val;
      this.selectedId = id;
      this.addObj.channel_id = channel_id;
      this.addObj.pay_mode = pay_mode;
      this.addObj.pay_type = pay_type;
      this.addObj.sourceT = sourceT;
      this.addObj.subject_code = subject_code;
      this.addObj.remark = remark;
      this.dialogShow = true;
      this.addType = "edit";
    },
    //关闭弹窗事件
    onLeave(){
      this.dialogShow = false;
      this.addObj.channel_id = "";
      this.addObj.pay_mode = "";
      this.addObj.pay_type = "";
      this.addObj.sourceT = "";
      this.addObj.subject_code = "";
      this.addObj.remark = "";
      this.selectedId = "";
    },
    //dialog确实按钮事件
    onGoon(formName){
      if (this.addLoading) return;
      let { addType, selectedId } = this;
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let params = {
            ...this.addObj,
          }
          let func = addAccountBook;
          if(addType == "edit"){
            params.id = selectedId;
            func = editAccountBook;
          }
          this.addLoading = true;
          const res = await func(params).catch(e => e).finally(()=>{this.addLoading = false;});
          this.addLoading = false;
          console.log(res)
          if(res.code != 200) return;
          this.dialogShow = false;
          this.getList();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
}
</script>
<style scoped lang="scss">
@import "@/common/style.scss";
.payChannelConfig.page {
  overflow-y: auto;
  .wrap {
    min-width: 1182px;
    min-height: 740px;
    border-radius: 2px;
    background: #ffffff;
    padding: 16px 16px 50px 16px;
    .creatTG {
      display: inline-block;
      width: 118px;
      height: 32px;
      background: #4D7EFA;
      border-radius: 2px;
      color: #FFFFFF;
      margin-bottom: 20px;
      cursor: pointer;
    }
    .tableList {
      // margin-top:10px;
      .page-box {
        margin-top: 17px;
        text-align: right;
      }
      .arrowMore{
        margin-right:12px;
        fill: #818899;
      }
    }
  }
  .dialog {
    height: auto;
    .dialogList {
      max-height: 500px;
      overflow-y: scroll;
      padding: 32px;
      .tit {
        margin-top: 25px;
        .serial {
          width: 24px;
          height: 24px;
          background: #F8A201;
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #FFFFFF;
          border-radius: 50%;
        }
        .text {
          padding-left: 8px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #565A66;
          line-height: 22px;
          .goLogin {
            display: inline-block;
            width: 70px;
            height: 22px;
            background: #4D7EFA;
            border-radius: 2px;
            color: #FFFFFF;
            line-height: 22px;
            text-align: center;
          }
        }
      }
      img {
        width: 100%;
        margin-top: 8px;
        margin-bottom: 24px;
      }
    }
    .dialog-footer {
      border-top: 1px solid #EBEEF5;
      height: 55px;
      padding: 0 16px;
      .dfcancle {
        margin-right: 8px;
        width: 74px;
        height: 32px;
        background: #FFFFFF;
        border-radius: 2px;
        border: 1px solid #DCDFE6;
        font-size: 14px;
        font-weight: 400;
        color: #565A66;
      }
      .dfconfirm {
        width: 74px;
        height: 32px;
        background: #4D7EFA;
        border-radius: 2px;
        border: 1px solid #DCDFE6;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
      }
    }
  }
}
</style>