<template>
  <el-dialog :visible.sync="dialogShow" :show-close="false" width="560px" :close-on-click-modal="false">
    <div class="payCheckDialog">
      <dialogHeader title="支付退款检测" @closeDialog="dialogShow = false"></dialogHeader>
      <div class="payCheckDialog_content">
        <el-form ref="checkForm" :model="checkForm" label-width="auto" status-icon @submit.native.prevent>
          <el-form-item label="appid" prop="scanPay">
            <el-input v-model="checkForm.appId" placeholder="请输入appid"></el-input>
          </el-form-item>
          <el-form-item prop="scanPay">
            <template #label>
              <span>付款码</span>
              <el-tooltip placement="top">
                <template #content>
                  <div>
                    配置人员打开微信或支付宝的付款码页面，输入付款码，完成支付和退款流程，若需要输入密码，会等待15秒后执行退款动作。
                    <span class="c-danger">注意，若超过15秒未完成支付，将退款失败。</span>
                  </div>
                </template>
                <i class="el-icon-info" style="color: #409EFF; margin-left: 4px;"></i>
              </el-tooltip>
            </template>
            <div flex="main:center cross:center">
              <el-input v-model="checkForm.payBarCode" placeholder="请输入微信或支付宝付款条形码号码"></el-input>
              <el-button :loading="scanTestLoading" type="primary" @click="onScanPayTest" style="margin-left: 12px;">测试</el-button>
            </div>
            <div class="scanCheckRes_content" v-html="scanCheckResText"></div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { microPayCheck } from "@/api/config";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    rowData: {
      type: Object,
      default: () => ({}),
    }
  },
  data() {
    return {
      checkForm: {
        appId: "",
        payBarCode: "",
      },
      scanTestLoading: false,
      scanCheckRes: {
        payRes: {
          code: 200,
          msg: "",
        },
        refundRes: {
          code: 200,
          msg: "",
        }
      }
    }
  },
  computed: {
    dialogShow: {
      get() {
        return this.visible;
      },
      set(val) {
        if (!val) {
          this.checkForm.appId = "";
          this.checkForm.payBarCode = "";
          this.scanCheckRes = {
            payRes: {
              code: 200,
              msg: "",
            },
            refundRes: {
              code: 200,
              msg: "",
            }
          }
        }
        this.$emit("update:visible", val);
      }
    },
    channelId() {
      if (!this.rowData) return "";
      return this.rowData.channel_id;
    },
    merchantId() {
      if (!this.rowData) return "";
      return this.rowData.member_id;
    },
    payType() {
      if (!this.rowData) return "";
      return this.rowData.pay_type;
    },
    scanCheckResText() {
      return Object.values(this.scanCheckRes).map(res => `<span class="${res.code == 200 ? 'c-success' : 'c-danger'}">${res.msg}</span>`).join("\n");
    }
  },
  methods: {
    onScanPayTest() {
      if (!this.checkForm.appId) return this.$message.warning("请先填写appid");
      if (!this.checkForm.payBarCode) return this.$message.warning("请先填写微信或支付宝付款条形码号码");
      this.scanTestLoading = true;
      microPayCheck({
        channel_id: this.channelId,
        merchant_id: this.merchantId,
        pay_type: this.payType,
        app_id: this.checkForm.appId,
        auth_code: this.checkForm.payBarCode,
      }).then(res => {
        this.handleData(res);
      }).catch(e => {
        this.handleData(e.data)
      }).finally(() => {
        this.scanTestLoading = false;
      })
    },
    handleData(res) {
      const { pay_res = {}, refund_res = {} } = res.data;
      this.scanCheckRes = {
        payRes: {
          code: pay_res.code,
          msg: "支付结果：" + (pay_res.msg || ""),
        },
        refundRes: {
          code: refund_res.code,
          msg: "退款结果：" + (refund_res.msg || ""),
        }
      }
    }
  }
}
</script>

<style lang="scss">
.payCheckDialog {
  height: auto;
  
  &_content {
    max-height: 500px;
    padding: 32px;
  }
  .scanCheckRes_content {
    white-space: pre-wrap;
    line-height: 20px;
  }
}
</style>