<template>
    <div class="searchBox">
        <template v-if="showMember">
            用户：
            <el-select
                size="small"
                v-model="memberType"
                placeholder="请选择"
                style="width: 120px"
            >
                <el-option
                    v-for="item in memberTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
            </el-select>
            <el-select
                ref="remoteSelect"
                v-show="[0, 1, 2].includes(memberType)"
                size="small"
                v-model="member_id"
                filterable
                remote
                reserve-keyword
                :placeholder="`请输入${memberTypeList[memberType].label}搜索用户`"
                :remote-method="remoteMethod"
                :loading="selectLoading"
                @hook:mounted="cancalReadOnly"
                @visible-change="cancalReadOnly"
                style="width: 260px; margin-right: 10px"
            >
                <el-option
                    v-for="item in memberList"
                    :key="item.id"
                    :label="item.dname"
                    :value="item.id"
                >
                </el-option>
            </el-select>
            <el-input 
                v-show="[3].includes(memberType)"
                v-model="member_id"
                size="small"
                placeholder="请输入用户ID"
                style="width: 260px; margin-right: 10px"
            >
            </el-input>
        </template>
        <template v-if="showUseEnv">
            应用环境：
            <el-select
                size="small"
                class="space"
                v-model="use_env"
                clearable
                placeholder="请选择状态"
                style="width: 180px"
            >
                <el-option
                    v-for="item in use_env_box"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
            </el-select>
        </template>
        <template v-if="showChannel">
          <el-select
              class="space"
              v-model="channel_id"
              placeholder="请选择渠道"
              size="small"
              clearable
              style="width: 180px"
          >
            <el-option
                v-for="item in channelList"
                :key="item.id"
                :label="item.channel_name"
                :value="item.channel_id"
            >
              <span>{{ `[${getChannelId(item.channel_id)}] ${item.channel_name}` }}</span>
            </el-option>
          </el-select>
        </template>
        <el-button size="small" @click="resetEvent">重置</el-button>
        <el-button size="small" type="primary" @click="searchEvent">
            搜索
        </el-button>
    </div>
</template>

<script>
import * as initStore from "@/store/init.js";
import { queryMemberByCondition } from "@/api/admin";

export default {
    props: {
        channel: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            selectLoading: false,
            //应用环境列表
            use_env_box: [
                {
                    value: "0",
                    label: "线下",
                },
                {
                    value: "1",
                    label: "微商城",
                },
                {
                    value: "2",
                    label: "线下+微商城",
                },
                {
                    value: "3",
                    label: "小程序",
                },
                {
                    value: 100,
                    label: "所有可独立收款的渠道",
                },
            ],
            use_env: "3", // 默认小程序
            memberTypeList: [
                {
                    value: 0,
                    label: "账号",
                },
                {
                    value: 1,
                    label: "手机号",
                },
                {
                    value: 2,
                    label: "名称",
                },
                {
                    value: 3,
                    label: "用户ID",
                },
            ],
            memberType: 0, // 用户搜索类型
            memberList: [], // 用户列表
            member_id: "",
            channel_id: "",
        };
    },
    computed: {
        channelList: () => initStore.state.channelList, // 渠道列表
        showUseEnv() {
            return ["wechat"].includes(this.channel);
        },
        showMember() {
            return ["wechat", "alipay", "baidu", "others"].includes(this.channel);
        },
        showChannel() {
            return ["others"].includes(this.channel);
        },
    },
    methods: {
        cancalReadOnly(onOff) {
            this.$nextTick(() => {
                if (!onOff) {
                const { remoteSelect } = this.$refs;
                const input = remoteSelect.$el.querySelector('.el-input__inner');
                input.removeAttribute('readonly');
                }
            });
        },
        getChannelId(id) {
          if (+id < 10) return `0${+id}`;
          return id;
        },
        async remoteMethod(val) {
            let pType = {
                0: "account",
                1: "mobile",
                2: "dname",
            };
            let params = {
                page_size: 100,
                [pType[this.memberType]]: val,
            };
            this.selectLoading = true;
            const res = await queryMemberByCondition(params);
            this.selectLoading = false;
            if (res.code != 200) return;
            this.memberList = res.data;
        },
        // 重置事件
        resetEvent() {
            this.use_env = "";
            this.member_id = "";
            this.channel_id = "";
            this.$emit("reset", {
                use_env: this.use_env,
                member_id: this.member_id,
                channel_id: this.channel_id,
            });
        },
        // 搜索事件
        searchEvent() {
            this.$emit("search", {
                use_env: this.use_env,
                member_id: this.member_id,
                channel_id: this.channel_id,
            });
        },
    },
};
</script>

<style></style>
