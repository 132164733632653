<template>
    <div>
        <filter-box
            channel="alipay"
            @reset="resetEvent"
            @search="searchEvent"
        ></filter-box>
        <div class="tableList">
            <!-- 表格部分 -->
            <el-table
                v-loading="tableLoading"
                :data="tableData"
                style="width: 100%"
                class="table-box"
                :header-cell-style="{
                    background: '#F5F5F5',
                    color: '#606266',
                }"
            >
                <el-table-column
                    prop="memberid"
                    label="用户ID(旧会员ID)"
                    min-width="150"
                >
                </el-table-column>
                <el-table-column prop="name" label="用户名称" min-width="200">
                </el-table-column>
                <el-table-column
                    prop="appid"
                    label="服务商APPID(appid)"
                    min-width="150"
                >
                </el-table-column>
                <el-table-column
                    prop="master_pid"
                    label="服务商商户号(master_pid)"
                    min-width="150"
                >
                </el-table-column>
                <el-table-column
                    prop="sub_appid"
                    label="户APPID(sub_appid)"
                    min-width="150"
                >
                </el-table-column>
                <el-table-column
                    prop="user_id"
                    label="子商户商户号(user_id)"
                    min-width="150"
                >
                </el-table-column>
                <el-table-column
                    prop="app_auth_token"
                    label="商户授权令牌(app_auth_token)"
                    min-width="150"
                >
                </el-table-column>
                <el-table-column
                    prop="created_at"
                    label="添加日期"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        {{ scope.row.created_at | $unixTimeToDateTime }}
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="page-box">
                <el-pagination
                    @current-change="fetchList"
                    :current-page.sync="page"
                    :page-size.sync="pageSize"
                    layout="total, prev, pager, next, jumper"
                    :total="total"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { getMerchantAliPayList } from "@/api/config";
import FilterBox from "./components/filterBox.vue";

export default {
    components: {
        FilterBox,
    },
    data() {
        return {
            page: 1,
            pageSize: 10,
            total: 0,
            tableLoading: false,
            tableData: [],
            searchParams: {
                member_id: "",
            },
        };
    },
    mounted() {
        this.fetchList();
    },
    methods: {
        async fetchList() {
            let { page, pageSize } = this;
            let { member_id } = this.searchParams;
            let params = {
                page,
                page_size: pageSize,
                member_id,
            };
            this.tableLoading = true;
            const res = await getMerchantAliPayList(params);
            this.tableLoading = false;
            if (res.code != 200) return;
            let { list, sum } = res.data;
            this.tableData = list || [];
            this.total = parseInt(sum);
        },
        resetEvent(params) {
            this.searchParams = params;
        },
        searchEvent(params) {
            let { member_id } = params;
            if (!member_id) return this.$message.error("请输入搜索条件");
            this.page = 1;
            this.searchParams = params;
            this.fetchList();
        },
    },
};
</script>

<style lang="scss"></style>
