<template>
    <div class="page chnanelConfig">
        <page-header :bread-crumb="true" :back-button="true" :sync="true" />
        <div class="wrap">
            <button class="creatTG f14w400" @click="addEvent"><i class="el-icon-plus"></i>添加渠道</button>
            <div class="tableList">
                <!-- 表格部分 -->
                <el-table v-loading="loading" :data="tableData" style="width: 100%" class="table-box" max-height="645" :header-cell-style="{background:'#F5F5F5',color:'#606266'}">
                    <el-table-column prop="channel_id" label="渠道编号" width="120" align="center">
                    </el-table-column>
                    <el-table-column prop="channel_name" label="渠道名称" min-width="180">
                    </el-table-column>
                    <el-table-column prop="state" label="状态" min-width="120">
                        <template slot-scope="scope">
                            {{statusBox[scope.row.state]}}
                        </template>
                    </el-table-column>
                    <el-table-column  label="操作" align="center" min-width="120">
                        <template slot-scope="scope">
                            <el-button
                            type="text"
                            style="padding: 0;color: #4D7EFA;"
                            class="f14h20w400"
                            @click="stateEvent(scope.row)"
                            >{{scope.row.state == 0 ?'禁用':'启用'}}</el-button>
                            <el-button
                            type="text"
                            style="padding: 0;color: #4D7EFA;"
                            class="f14h20w400"
                            @click="editEvent(scope.row)"
                            >编辑</el-button>
                            <el-button
                            type="text"
                            style="padding: 0;color: #4D7EFA;"
                            class="f14h20w400"
                            @click="deleteEvent(scope.row)"
                            >删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 分页 -->
                <div class="page-box ">
                    <el-pagination @current-change="handleCurrentChange" :current-page="page" :page-size="pageSize" layout="total, prev, pager, next, jumper" :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
        <el-dialog :visible.sync="dialogShow" :show-close="false" v-if="dialogShow" width="560px" clearable="true" :close-on-click-modal="false">
            <div class="dialog">
                <dialogHeader :title="addType == 'add'?'添加渠道':'编辑渠道'" @closeDialog="onLeave"></dialogHeader>
                <div class="dialogList">
                    <el-form :rules="rules"  ref="addObj" :model="addObj" label-width="120px">
                        <el-form-item label="渠道名称：" prop="channel_name">
                            <el-input v-model.trim="addObj.channel_name"></el-input>
                        </el-form-item>
                         <el-form-item label="渠道编号：" prop="channel_serial"  v-if="addType == 'add'">
                            <el-input v-model.trim="addObj.channel_serial"></el-input>
                        </el-form-item>
                    </el-form>
                    <!-- <el-form :rules="rules"  ref="addObj" :model="addObj" label-width="120px" v-if="addType == 'add'">
                        <el-form-item label="渠道编号：" prop="channel_serial">
                            <el-input v-model.trim="addObj.channel_serial"></el-input>
                        </el-form-item>
                    </el-form> -->
                </div>
                <div slot="footer" class="dialog-footer" flex="cross:center main:right">
                    <button @click="onLeave" class="dfcancle">取 消</button>
                    <button @click="onGoon('addObj')" class="dfconfirm">确 定</button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import { Message } from 'element-ui';
    import {getChannelList,addChannel,editChannel,delChannel,setChannelState} from "@/api/config"
    export default {
        name: "chnanelConfig",
        data() {
            return {
                loading:false,
                page:1,
                pageSize:10,
                total:0,
                tableData:[],
                addType:'add',
                dialogShow:false,
                statusBox:{
                    0:'启用',
                    1:'禁用'
                },
                rules:{
                    channel_name: [{
                        required: true,
                        message: "渠道名称不能为空",
                        trigger: "blur"
                    }],
                    channel_serial:[{
                        required: true,
                        message: "渠道编号不能为空",
                        trigger: "blur"
                    }],
                },
                addObj:{
                    channel_name:'',      //渠道名称
                    channel_serial:'',    //渠道编号
                },
                channel_id:'',
                addLoading:false, //数据提交状态
            }
        },
        mounted() {
            this.getList();
        },
        methods: {
            async getList(){
                const {page,pageSize} = this;
                let params = {
                    page:page,
                    page_size:pageSize
                }
                this.loading = true;
                const res = await getChannelList(params).catch(e => e).finally(() => {this.loading = false});
                console.log(res);
                this.loading = false;
                if(res.code){
                    this.tableData = res.data && res.data.list || [];
                    this.total = res.data.total || 0;
                }
                
            },
            //page改变 促发事件
            handleCurrentChange(val){
                if(this.page == val) return;
                this.page = val;
                this.getList();
            },
            //新增事件
            addEvent(){
                this.dialogShow = true;
                this.addType = 'add' ;
            },
            //编辑事件
            editEvent(val){
                let {channel_name,id} = val;
                this.addObj.channel_name = channel_name;
                this.channel_id = id;
                this.dialogShow = true;
                this.addType = 'edit' ;

            },
            //状态更新
            async stateEvent(val){
                let text = `${val.state == 0 ?'禁用':'启用'}渠道“${val.channel_name}”`
                const result = await this.$helper.default.message.confirm({
                    title: `提示`,
                    content: text
                    }).catch(e => e)
                if(result == 'cancel') return;
                let params = {
                   id:val.id,
                   state:val.state == 0?1:0
                }
                const res = await setChannelState(params).catch(e => e);
                if(res.code != 200) return;
                Message.success('保存成功');
                this.getList();
            },
            //删除事件
            async deleteEvent(val){
                let text = `删除渠道“${val.channel_name}”`
                const result = await this.$helper.default.message.confirm({
                    title: `提示`,
                    content: text
                    }).catch(e => e)
                if(result == 'cancel') return;
                let params = {
                    id:val.id
                }
                const res = await delChannel(params).catch(e => e);
                if(res.code != 200) return;
                Message.success('删除成功')
                this.getList();
            },
            //关闭弹窗事件
            onLeave(){
                this.dialogShow = false;
                this.addObj.channel_name = '';
                this.channel_id = '';
                this.addObj.channel_serial = '';
            },
            //dialog确实按钮事件
            onGoon(formName){
                if(this.addLoading) return;
                let {addType,channel_id} = this;
                let {channel_name,channel_serial} = this.addObj;
                this.$refs[formName].validate(async (valid) => {
                    if (valid) {
                        let params = {
                            channel_name:channel_name,
                        }
                        let func = addChannel;
                        if(addType == "edit"){
                            params.id = channel_id;
                            func = editChannel;
                        }else{
                            params.channel_id = channel_serial;
                        }
                        this.addLoading = true;
                        const res = await func(params).catch(e => e).finally(()=>{this.addLoading = false;});
                        this.addLoading = false;
                        console.log(res)
                        if(res.code != 200) return;
                        this.dialogShow = false;
                        this.getList();
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            }
        }
    }
</script>
<style scoped lang="scss">
    @import "@/common/style.scss";
    .chnanelConfig.page {
        overflow-y: auto;
        .wrap {
            min-width: 1182px;
            min-height: 740px;
            border-radius: 2px;
            background: #ffffff;
            padding: 16px 16px 50px 16px;
            .creatTG {
                display: inline-block;
                width: 98px;
                height: 32px;
                background: #4D7EFA;
                border-radius: 2px;
                color: #FFFFFF;
                margin-bottom: 20px;
                cursor: pointer;
            }
            .tableList {
                // margin-top:10px;
                .page-box {
                    margin-top: 17px;
                    text-align: right;
                }
                .arrowMore{
                    margin-right:12px;
                    fill: #818899;
                }
            }
        }
        .dialog {
            height: auto;
            .dialogList {
                max-height: 500px;
                overflow-y: scroll;
                padding: 32px 70px 40px 70px;
                .tit {
                    margin-top: 25px;
                    .serial {
                        width: 24px;
                        height: 24px;
                        background: #F8A201;
                        font-size: 14px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #FFFFFF;
                        border-radius: 50%;
                    }
                    .text {
                        padding-left: 8px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #565A66;
                        line-height: 22px;
                        .goLogin {
                            display: inline-block;
                            width: 70px;
                            height: 22px;
                            background: #4D7EFA;
                            border-radius: 2px;
                            color: #FFFFFF;
                            line-height: 22px;
                            text-align: center;
                        }
                    }
                }
                img {
                    width: 100%;
                    margin-top: 8px;
                    margin-bottom: 24px;
                }
            }
            .dialog-footer {
                border-top: 1px solid #EBEEF5;
                height: 55px;
                padding: 0 16px;
                .dfcancle {
                    margin-right: 8px;
                    width: 74px;
                    height: 32px;
                    background: #FFFFFF;
                    border-radius: 2px;
                    border: 1px solid #DCDFE6;
                    font-size: 14px;
                    font-weight: 400;
                    color: #565A66;
                }
                .dfconfirm {
                    width: 74px;
                    height: 32px;
                    background: #4D7EFA;
                    border-radius: 2px;
                    border: 1px solid #DCDFE6;
                    font-size: 14px;
                    font-weight: 400;
                    color: #FFFFFF;
                }
            }
        }
    }
</style>