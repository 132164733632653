<template>
    <div class="page secondaryPwdConfig">
        <page-header :bread-crumb="true" :back-button="true" :sync="true" />
        <div v-loading="loading" class="wrap">
            <template v-if="!hasPwd">
                <p class="c-error tips">
                    您还未设置二级密码，可通过下方表单进行首次设置
                </p>
                <p class="c-info tips">注：二级密码主要用于退款安全验证</p>
                <el-form
                    class="secondaryPwdForm"
                    :model="ruleForm"
                    status-icon
                    :rules="rules"
                    ref="ruleForm"
                    label-width="100px"
                >
                    <el-form-item label="新密码" prop="password">
                        <el-input
                            type="password"
                            v-model="ruleForm.password"
                            autocomplete="off"
                            show-password
                            style="width: 400px"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="确认密码" prop="checkPass">
                        <el-input
                            type="password"
                            v-model="ruleForm.checkPass"
                            autocomplete="off"
                            show-password
                            style="width: 400px"
                        ></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button
                            :loading="submitLoading"
                            size="small"
                            type="primary"
                            @click="submitForm('ruleForm')"
                        >
                            提交
                        </el-button>
                    </el-form-item>
                </el-form>
            </template>
            <el-result v-else icon="success" title="您已成功设置过二级密码">
                <template slot="subTitle">
                    <span class="c-info">
                        若遗忘密码，请联系技术人员进行修改
                    </span>
                </template>
            </el-result>
        </div>
    </div>
</template>

<script>
import { encode } from "js-base64";
import { isSetSecurityConf, addSecurityConf } from "@/api/config";

export default {
    data() {
        var validatePass = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入密码"));
            } else {
                if (this.ruleForm.checkPass !== "") {
                    this.$refs.ruleForm.validateField("checkPass");
                }
                if (
                    !/^(?![0-9]+$)(?![a-zA-Z]+$)(?![-+_!@#$%^&*()]+$)[0-9A-Za-z@_!$&%]{6,16}$/.test(
                        value
                    )
                ) {
                    callback(new Error("请输入6-20位字符，必须包含数字、字母"));
                }
                callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请再次输入密码"));
            } else if (value !== this.ruleForm.password) {
                callback(new Error("两次输入密码不一致!"));
            } else {
                callback();
            }
        };
        return {
            hasPwd: false,
            loading: false,
            submitLoading: false,
            ruleForm: {
                password: "",
                checkPass: "",
            },
            rules: {
                password: [
                    // {
                    //     required: true,
                    //     pattern: /^(?![A-Za-z]+$)(?![0-9]+$)[A-Za-z0-9]{6,20}$/,
                    //     message: "请输入6-20位字符，必须包含数字、字母",
                    //     trigger: "change",
                    // },
                    {
                        required: true,
                        validator: validatePass,
                        trigger: "blur",
                    },
                ],
                checkPass: [
                    {
                        required: true,
                        validator: validatePass2,
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    created() {
        this.loading = true;
    },
    mounted() {
        this.getPwdExist();
    },
    methods: {
        async getPwdExist() {
            const res = await isSetSecurityConf().catch((e) => {
                this.loading = false;
            });
            if (res.code == 200) {
                this.hasPwd = !!res.data.security_conf;
            }
            this.loading = false;
        },
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    const params = {
                        decipher: encode(this.ruleForm.password),
                    };
                    this.submitLoading = true;
                    const res = await addSecurityConf(params).catch((e) => {
                        this.submitLoading = false;
                    });
                    this.submitLoading = false;
                    if (res.code == 200) {
                        this.$message.success("设置成功");
                        this.hasPwd = true;
                    } else {
                        this.$message.error(res.msg);
                    }
                } else {
                    return false;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/common/style.scss";
.secondaryPwdConfig.page {
    overflow-y: auto;

    .wrap {
        min-width: 1182px;
        min-height: 740px;
        border-radius: 2px;
        background: #ffffff;
        padding: 16px 16px 50px 32px;

        .tips {
            margin: 10px 0;
        }

        .c-error {
            color: #f9614a;
        }

        .c-info {
            color: #909399;
            font-size: 12px;
        }

        .secondaryPwdForm {
            margin-top: 20px;
        }
    }
}
</style>
