<template>
    <div>
        <el-button
            size="small"
            type="primary"
            icon="el-icon-plus"
            @click="addEvent"
            style="margin-bottom: 10px"
        >
            新增配置
        </el-button>
        <filter-box
            channel="wechat"
            @reset="resetEvent"
            @search="searchEvent"
        ></filter-box>
        <div class="tableList">
            <!-- 表格部分 -->
            <el-table
                v-loading="tableLoading"
                :data="tableData"
                style="width: 100%"
                class="table-box"
                :header-cell-style="{
                    background: '#F5F5F5',
                    color: '#606266',
                }"
            >
                <el-table-column
                    prop="memberid"
                    label="用户ID(旧会员ID)"
                    width="150"
                >
                </el-table-column>
                <el-table-column prop="name" label="用户名称" min-width="180">
                </el-table-column>
                <el-table-column
                    prop="appid"
                    label="服务商APPID(appid)"
                    min-width="180"
                >
                </el-table-column>
                <el-table-column
                    prop="merchant_id"
                    label="服务商商户号(mchid)"
                    min-width="180"
                >
                </el-table-column>
                <el-table-column
                    prop="sub_appid"
                    label="子商户APPID(sub_appid)"
                    min-width="180"
                >
                </el-table-column>
                <el-table-column
                    prop="sub_merchant_id"
                    label="子商户商户号(sub_mch_id)"
                    min-width="180"
                >
                </el-table-column>
                <el-table-column
                    prop="payType_name"
                    label="是否独立收款"
                    width="140"
                >
                    <template slot-scope="scope">
                        <el-switch
                            :value="scope.row.status == 1 ? true : false"
                            @change="statusChange(scope.row)"
                        >
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="created_at"
                    label="添加日期"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        {{ scope.row.created_at | $unixTimeToDateTime }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            style="padding: 0; color: #4d7efa"
                            class="f14h20w400"
                            @click="editWechat(scope.row)"
                        >
                            编辑
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="page-box">
                <el-pagination
                    @current-change="fetchList"
                    :current-page.sync="page"
                    :page-size.sync="pageSize"
                    layout="total, prev, pager, next, jumper"
                    :total="total"
                >
                </el-pagination>
            </div>
        </div>
        <wechat-dialog
            :visible.sync="dialogVisible"
            :wechatAddType="dialogType"
            :wechatConfig="currentRow"
            @refresh="refreshList"
        ></wechat-dialog>
    </div>
</template>

<script>
import { getMerchantWePayList, setMerchantIndependent } from "@/api/config";
import FilterBox from "./components/filterBox.vue";
import WechatDialog from "./components/wechatDialog.vue";

export default {
    components: {
        FilterBox,
        WechatDialog,
    },
    data() {
        return {
            page: 1,
            pageSize: 10,
            total: 0,
            tableLoading: false,
            tableData: [],
            searchParams: {
                use_env: "3",
                member_id: "",
            },
            dialogVisible: false,
            dialogType: "add",
            currentRow: null,
        };
    },
    mounted() {
        this.fetchList();
    },
    methods: {
        async fetchList() {
            let { page, pageSize } = this;
            let { use_env, member_id } = this.searchParams;
            let params = {
                page,
                page_size: pageSize,
                use_env,
                member_id,
            };
            this.tableLoading = true;
            const res = await getMerchantWePayList(params);
            this.tableLoading = false;
            if (res.code != 200) return;
            let { list, sum } = res.data;
            this.tableData = list || [];
            this.total = parseInt(sum);
        },
        addEvent() {
            this.dialogVisible = true;
            this.dialogType = "add";
        },
        resetEvent(params) {
            this.searchParams = params;
        },
        searchEvent(params) {
            let { use_env, member_id } = params;
            if (!use_env && !member_id)
                return this.$message.error("请输入搜索条件");
            this.page = 1;
            this.searchParams = params;
            this.fetchList();
        },
        async statusChange(val) {
            let text = val.status == 1 ? "关闭独立收款" : "开启独立收款";
            const result = await this.$helper.default.message
                .confirm({
                    title: `提示`,
                    content: text,
                })
                .catch((e) => e);
            if (result == "cancel") return;
            let params = {
                id: val.id,
                status: val.status == 1 ? 0 : 1,
            };
            await setMerchantIndependent(params).catch((e) => e);
            this.fetchList();
        },
        editWechat(val) {
            this.currentRow = val;
            this.dialogType = "edit";
            this.dialogVisible = true;
        },
        refreshList() {
            if (this.dialogType === "add") {
                this.page = 1;
                this.fetchList();
            } else {
                this.fetchList();
            }
        },
    },
};
</script>

<style lang="scss"></style>
