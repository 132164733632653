<template>
    <el-dialog
        v-if="visible"
        :visible="visible"
        :show-close="false"
        width="660px"
        :close-on-click-modal="false"
    >
        <div class="dialog">
            <dialogHeader
                title="新增配置"
                @closeDialog="onClose"
            ></dialogHeader>
            <div class="dialogList">
                <el-form
                    :rules="rules"
                    ref="dialogForm"
                    :model="dialogForm"
                    label-width="120px"
                >
                    <el-form-item label="百度AppId：" prop="appid">
                        <el-input v-model.trim="dialogForm.appid"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
                flex="cross:center main:right"
            >
                <el-button size="small" @click="onClose">取 消</el-button>
                <el-button
                    size="small"
                    type="primary"
                    :loading="addLoading"
                    @click="onSumbit('dialogForm')"
                >
                    确 定
                </el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import { addBaiDuConfig } from "@/api/config";

export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            rules: {
                appid: [
                    {
                        required: true,
                        message: "百度AppId不能为空",
                        trigger: "blur",
                    },
                ],
            },
            dialogForm: {
                appid: "",
            },
            selectLoading: false,
            addLoading: false,
        };
    },
    watch: {
        visible(val) {
            if (val) {
            } else {
                this.dialogForm = {
                    appid: "",
                };
            }
        },
    },
    methods: {
        onClose() {
            this.$emit("update:visible", false);
        },
        onSumbit(formName) {
            if (this.addLoading) return;
            let { appid } = this.dialogForm;
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    let params = {
                        appid,
                    };
                    this.addLoading = true;
                    const res = await addBaiDuConfig(params).catch((e) => {
                        this.addLoading = false;
                    });
                    this.addLoading = false;
                    if (res.code != 200) return;
                    this.onClose();
                    this.$emit("refresh");
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
    },
};
</script>

<style></style>
