<template>
    <el-dialog
        v-if="visible"
        :visible="visible"
        :show-close="false"
        width="660px"
        :close-on-click-modal="false"
    >
        <div class="dialog">
            <dialogHeader
                :title="wechatAddType == 'add' ? '新增配置' : '编辑配置'"
                @closeDialog="onLeave"
            ></dialogHeader>
            <div class="dialogList">
                <el-form
                    :rules="rules"
                    ref="wechatAddObj"
                    :model="wechatAddObj"
                    label-width="120px"
                >
                    <el-form-item
                        label="供应商"
                        prop="member_id"
                        v-if="wechatAddType == 'add'"
                    >
                        <el-select
                            v-model="memberType"
                            placeholder="请选择"
                            style="width: 120px"
                            size="small"
                        >
                            <el-option
                                v-for="item in memberTypeList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                        <el-select
                            size="small"
                            v-model="wechatAddObj.member_id"
                            filterable
                            remote
                            reserve-keyword
                            :placeholder="`请输入${memberTypeList[memberType].label}搜索用户`"
                            :remote-method="remoteMethod"
                            :loading="selectLoading"
                            style="width: 280px"
                        >
                            <el-option
                                v-for="item in memberList"
                                :key="item.id"
                                :label="item.dname"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="子商户号：" prop="sub_mch_id">
                        <el-input
                            v-model.trim="wechatAddObj.sub_mch_id"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="appid：" prop="sub_appid">
                        <el-input
                            v-model.trim="wechatAddObj.sub_appid"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="应用环境：" prop="use_env">
                        <el-select
                            v-model="wechatAddObj.use_env"
                            placeholder="请选择状态"
                            style="width: 180px"
                        >
                            <el-option
                                v-for="item in use_env_box"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
                flex="cross:center main:right"
            >
                <el-button size="small" @click="onLeave">取 消</el-button>
                <el-button
                    size="small"
                    type="primary"
                    :loading="wechatAddLoading"
                    @click="onSumbit('wechatAddObj')"
                >
                    确 定
                </el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import { editMerchantWePay, addMerchantWePay } from "@/api/config";
import { queryMemberByCondition } from "@/api/admin";

export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        wechatAddType: {
            type: String,
            default: "add",
        },
        wechatConfig: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            rules: {
                member_id: [
                    {
                        required: true,
                        message: "供应商id不能为空",
                        trigger: "blur",
                    },
                ],
                sub_mch_id: [
                    {
                        required: true,
                        message: "子商户号不能为空",
                        trigger: "blur",
                    },
                ],
                sub_appid: [
                    {
                        required: true,
                        message: "appid不能为空",
                        trigger: "blur",
                    },
                ],
                use_env: [
                    {
                        required: true,
                        message: "应用环境不能为空",
                        trigger: "blur",
                    },
                ],
            },
            wechatAddObj: {
                id: "",
                member_id: "", // 供应商 id
                sub_mch_id: "", // 子商户号
                sub_appid: "", //公众号 appid 或小程序 appid
                use_env: "", //应用环境:0 线下,1 微商城,2 线下+微商城,3 小程序,100 所有可独立收款的渠道
            },
            selectLoading: false,
            //应用环境列表
            use_env_box: [
                {
                    value: "0",
                    label: "线下",
                },
                {
                    value: "1",
                    label: "微商城",
                },
                {
                    value: "2",
                    label: "线下+微商城",
                },
                {
                    value: "3",
                    label: "小程序",
                },
                {
                    value: 100,
                    label: "所有可独立收款的渠道",
                },
            ],
            use_env: "3", // 默认小程序
            memberTypeList: [
                {
                    value: 0,
                    label: "账号",
                },
                {
                    value: 1,
                    label: "手机号",
                },
                {
                    value: 2,
                    label: "名称",
                },
            ],
            memberType: 0, // 用户搜索类型
            memberList: [], // 用户列表
            wechatAddLoading: false,
        };
    },
    watch: {
        visible(val) {
            if (val) {
                if (this.wechatAddType === "edit" && this.wechatConfig) {
                    let { id, sub_merchant_id, sub_appid, use_env } =
                        this.wechatConfig;
                    this.wechatAddObj.id = id;
                    this.wechatAddObj.sub_mch_id = sub_merchant_id;
                    this.wechatAddObj.sub_appid = sub_appid;
                    this.wechatAddObj.use_env = use_env;
                }
            } else {
                this.wechatAddObj = {
                    id: "",
                    member_id: "", // 供应商 id
                    sub_mch_id: "", // 子商户号
                    sub_appid: "", //公众号 appid 或小程序 appid
                    use_env: "", //应用环境:0 线下,1 微商城,2 线下+微商城,3 小程序,100 所有可独立收款的渠道
                };
            }
        },
    },
    methods: {
        async remoteMethod(val) {
            let pType = {
                0: "account",
                1: "mobile",
                2: "dname",
            };
            let params = {
                page_size: 100,
                [pType[this.memberType]]: val,
            };
            this.selectLoading = true;
            const res = await queryMemberByCondition(params);
            this.selectLoading = false;
            if (res.code != 200) return;
            this.memberList = res.data;
        },
        onLeave() {
            this.$emit("update:visible", false);
        },
        onSumbit(formName) {
            if (this.wechatAddLoading) return;
            let { wechatAddType } = this;
            let { id, member_id, sub_mch_id, sub_appid, use_env } =
                this.wechatAddObj;
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    let func = addMerchantWePay;
                    let params = {
                        sub_mch_id,
                        sub_appid,
                        use_env,
                    };
                    if (wechatAddType == "edit") {
                        params.id = id;
                        func = editMerchantWePay;
                    } else {
                        params.member_id = member_id;
                        params.name = this.memberList[member_id].dname;
                    }
                    this.wechatAddLoading = true;
                    const res = await func(params);
                    this.wechatAddLoading = false;
                    if (res.code != 200) return;
                    this.onLeave();
                    this.$emit("refresh");
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
    },
};
</script>

<style></style>
