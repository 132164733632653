<template>
  <div class="page supplierConfig">
    <page-header :bread-crumb="true" :back-button="true" :sync="true" />
    <div class="wrap">
      <button class="creatTG f14w400" @click="addEvent"><i class="el-icon-plus"></i>添加供应商</button>
      <div class="searchBox">
        <el-select size="small" v-model="memberType" placeholder="请选择" style="width:120px;">
          <el-option v-for="item in memberTypeList" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-select
          ref="remoteSelect"
          v-show="[0, 1, 2].includes(memberType)"
          class="space"
          size="small"
          v-model="searchBox.member_id"
          filterable
          remote
          reserve-keyword
          :placeholder="`请输入${memberTypeList[memberType].label}搜索用户`"
          :remote-method="remoteMethod"
          :loading="selectLoading"
          @hook:mounted="cancalReadOnly"
          @visible-change="cancalReadOnly"
          style="width:260px;"
        >
          <el-option v-for="item in memberList" :key="item.id" :label="item.dname" :value="item.id">
          </el-option>
        </el-select>
        <el-input v-show="[3].includes(memberType)" class="space" v-model="searchBox.member_id" size="small" placeholder="请输入用户ID" style="width:260px;"></el-input>
        <el-select class="space" v-model="searchBox.channel_id" placeholder="请选择渠道" size="small" style="width:180px;">
          <el-option v-for="item in channelList" :key="item.id" :label="item.channel_name" :value="item.channel_id">
          </el-option>
        </el-select>
        <el-select class="space" v-model="searchBox.state" placeholder="请选择状态" size="small" style="width:180px;">
          <el-option v-for="item in statusBox" :key="item.id" :label="item.label" :value="item.id">
          </el-option>
        </el-select>
        <el-select class="space" v-model="searchBox.pay_type" placeholder="请选择支付方式" size="small" style="width:180px;">
          <el-option v-for="item in payTypeList" :key="item.id" :label="item.payType_name" :value="item.id">
          </el-option>
        </el-select>
        <button class="reset" @click="resetEvent">重置</button>
        <button class="search f14h20w400" @click="searchEvent">搜索</button>
      </div>
      <div class="tableList">
        <!-- 表格部分 -->
        <el-table v-loading="loading" :data="tableData" style="width: 100%" class="table-box" max-height="530" :header-cell-style="{background:'#F5F5F5',color:'#606266'}">
          <el-table-column prop="member_id" label="供应商ID" width="180">
          </el-table-column>
          <el-table-column prop="dname" label="供应商名称" min-width="180">
          </el-table-column>
          <el-table-column prop="channel_name" label="渠道" min-width="120">
          </el-table-column>
          <el-table-column prop="pay_type_name" label="支付方式" min-width="120">
          </el-table-column>
          <el-table-column prop="status_name" label="状态" min-width="120">
          </el-table-column>
          <el-table-column  label="操作" align="center" min-width="120">
            <template slot-scope="scope">
              <el-button
                  type="text"
                  style="padding: 0;color: #4D7EFA;"
                  class="f14h20w400"
                  @click="editEvent(scope.row)"
              >编辑</el-button>
              <el-button
                  v-if="isShowPayCheck(scope.row)"
                  type="text"
                  style="padding: 0;color: #4D7EFA;"
                  class="f14h20w400"
                  @click="payCheckEvent(scope.row)"
              >支付检测</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="page-box ">
          <el-pagination @current-change="handleCurrentChange" :current-page="page" :page-size="pageSize" layout="total, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogShow" :show-close="false" v-if="dialogShow" width="560px" clearable="true" :close-on-click-modal="false">
      <div class="dialog">
        <dialogHeader :title="addType == 'add'?'添加供应商配置':'编辑供应商配置'" @closeDialog="onLeave"></dialogHeader>
        <div class="dialogList">
          <el-form :rules="rules"  ref="addObj" :model="addObj" label-suffix="：" label-width="120px">
            <el-form-item v-if="addType == 'add'" label="供应商" prop="member_id" >
              <el-select size="small" v-model="memberType" placeholder="请选择" style="width:120px;">
                <el-option v-for="item in memberTypeList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <el-select size="small" v-model="addObj.member_id" filterable remote reserve-keyword :placeholder="`请输入${memberTypeList[memberType].label}搜索用户`" :remote-method="remoteMethod" :loading="selectLoading" style="width:260px;">
                <el-option v-for="item in memberList" :key="item.id" :label="item.dname" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="addType == 'edit'" label="供应商" prop="member_id" >
              <span>{{ currentDname }}</span>
            </el-form-item>
            <el-form-item label="渠道" prop="channel_id" >
              <el-select v-model="addObj.channel_id" placeholder="选择渠道" size="small" style="width:280px;">
                <el-option v-for="(item,index) in channelList" :key="index" :label="item.channel_name" :value="item.channel_id" >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="支付方式" prop="pay_type" v-if="addType == 'add'">
              <el-select v-model="addObj.pay_type" placeholder="支付方式" size="small" style="width:280px;">
                <el-option v-for="(item,index) in payTypeList" :key="index" :label="item.payType_name" :value="item.id" >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="状态" prop="state" >
              <el-select v-model="addObj.state" placeholder="选择状态" size="small" style="width:280px;" >
                <el-option v-for="item in addStatusBox" :key="item.id" :label="item.label" :value="item.id" >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="不允许的方法" prop="not_allow_method" >
              <el-select class="space" v-model="addObj.not_allow_method" placeholder="请选择操作类型" size="small" style="width:280px;" :multiple="true">
                <el-option v-for="(item,key) in pay_method_list" :key="key" :label="item" :value="key">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer" flex="cross:center main:right">
          <button @click="onLeave" class="dfcancle">取 消</button>
          <button @click="onGoon('addObj')" class="dfconfirm">确 定</button>
        </div>
      </div>
    </el-dialog>
    <pay-check-dialog :visible.sync="payCheckDialogVisible" :row-data="selectedRow"></pay-check-dialog>
  </div>
</template>

<script>
import {getMerchantList,editMerchant,addMerchant} from "@/api/config"
import {queryMemberByCondition,queryMemberByIdList}  from "@/api/admin"
import {getAllPayType}  from "@/api/order"
import * as initStore from "@/store/init.js"
import payCheckDialog from "./components/payCheckDialog.vue";
export default {
  name: "supplierConfig",
  components: {
    payCheckDialog
  },
  data() {
    return {
      searchBox: {
        member_id:'',    //供应商 id
        pay_type:'',     //支付方式编码
        channel_id:'',   //渠道编码
        state:-1,
      },
      statusBox:[
        {
          id:0,
          label:'禁用'
        },{
          id:1,
          label:'启用'
        },{
          id:-1,
          label:"全部"
        }
      ],
      addStatusBox:[
        {
          id:'0',
          label:'禁用'
        },{
          id:'1',
          label:'启用'
        }
      ],
      tableData:[],
      page:1,
      pageSize:10,
      total:0,
      loading:false,
      getType:'all',   //all==所有 search==搜索 
      dialogShow:false,
      currentDname: "", //当前编辑的供应商名称
      addObj:{
        id:'',                //供应商配置自增 id
        member_id:'',         //供应商 id
        pay_type:'',          //支付方式编号
        channel_id:'',        //渠道编号
        state:'',             //状态
        not_allow_method:[],  //不允许的方法
      },
      rules:{
        member_id: [{
          required: true,
          message: "供应商不能为空",
          trigger: "blur"
        }],
        pay_type:[{
          required: true,
          message: "支付方式不能为空",
          trigger: "blur"
        }],
        channel_id: [{
          required: true,
          message: "渠道不能为空",
          trigger: "blur"
        }],
        state:[{
          required: true,
          message: "状态不能为空",
          trigger: "blur"
        }],
      },
      addType:'add',
      pay_method_list:'',    //支付方法列表
      addLoading:false,
      memberTypeList: [{
        value: 0,
        label: '账号'
      },
        {
          value: 1,
          label: '手机号'
        },
        {
          value: 2,
          label: '名称'
        },
        {
          value: 3,
          label: "用户ID",
        },
      ],
      memberType:0,
      memberList:[],
      selectedRow: null,
      selectLoading:false,
      payCheckDialogVisible:false,
    }
  },
  computed:{
    payTypeList:()=>initStore.state.payTypeList,  //支付类型列表
    channelList:()=>initStore.state.channelList,  //渠道列表
  },
  mounted() {
    this.init();
  },
  methods: {
    cancalReadOnly(onOff) {
      this.$nextTick(() => {
        if (!onOff) {
          const { remoteSelect } = this.$refs;
          const input = remoteSelect.$el.querySelector('.el-input__inner');
          input.removeAttribute('readonly');
        }
      });
    },
    init(){
      this.getAllPayType();
      this.getList();
    },
    isShowPayCheck(row) {
      // 因为目前只支持被扫支付检测，除了百度聚合支付和绿聚支付，其他都支持
      return ![13, 19].includes(+row.channel_id);
    },
    async getAllPayType(){
      const res = await getAllPayType().catch(e => e);
      if(res.code == 200){
        let {pay_method_list} = res.data;
        this.pay_method_list = pay_method_list || {};
      }
    },
    async getList(){
      const {page,pageSize} = this;
      let params = {
        page:page,
        page_size:pageSize
      }
      if(this.getType == 'search'){
        params = Object.assign(params,this.searchBox)
      }else{
        params.state = -1
      }
      this.loading = true;
      const res = await getMerchantList(params).catch(e => e).finally(() => {this.loading = false});
      this.loading = false;
      if(res.code == 200){
        let tableData = res.data && res.data.list || [];
        this.total = res.data.total || 0;
        //表格数据处理
        tableData.forEach(table => {
          this.statusBox.forEach(status => {
            if(status.id == table.state) table.status_name = status.label
          })
        })
        //获取供应商名称
        let member_ids = tableData.length && tableData.map(item => item.member_id);
        let memberParams = {
          id_list:JSON.stringify(member_ids)
        }
        const resMember = await queryMemberByIdList(memberParams).catch(e => e);
        if(resMember.code != 200) return;
        let memberList = resMember.data || {};
        tableData.forEach(table => {
          table.dname = memberList[table.member_id] && memberList[table.member_id].dname || '';
        })
        //获取供应商名称
        this.tableData = tableData;
        //表格数据处理
      }
    },
    //添加事件
    addEvent(){
      this.addType = "add";
      this.dialogShow = true;
      this.addObj = {
        id:'',                //供应商配置自增 id
        member_id:'',         //供应商 id
        pay_type:'',          //支付方式编号
        channel_id:'',        //渠道编号
        state:'',             //状态
        not_allow_method:[],  //不允许的方法
      }
      this.currentDname = "";
    },
    //重置事件
    resetEvent(){
      this.searchBox = {
        member_id:'',    //供应商 id
        pay_type:'',     //支付方式编码
        channel_id:'',   //渠道编码
        state:-1,
      },
          this.getType = 'all';
      this.page = 1;
      this.getList();
    },
    //搜索事件
    searchEvent(){
      this.getType = 'search';
      this.page = 1;
      this.getList();
    },
    //page改变 促发事件
    handleCurrentChange(val){
      if(this.page == val) return;
      this.page = val;
      this.getList();
    },
    //编辑事件
    editEvent(val){
      let {id,member_id,dname,channel_id,state,not_allow_method} = val;
      not_allow_method = JSON.parse(not_allow_method);
      if(!not_allow_method.length){
        not_allow_method = [];
      }
      this.addObj.id = id;
      this.addObj.channel_id = channel_id;
      this.addObj.member_id = member_id;
      this.addObj.state = state;
      this.addObj.not_allow_method = not_allow_method;
      this.currentDname = dname;
      this.addType = 'edit' ;
      this.dialogShow = true;

    },
    //支付检测事件
    payCheckEvent(val) {
      this.selectedRow = val;
      this.payCheckDialogVisible = true;
    },
    //关闭弹窗事件
    onLeave(){
      this.dialogShow = false;
      this.addObj = {
        id:'',                //供应商配置自增 id
        member_id:'',         //供应商 id
        pay_type:'',          //支付方式编号
        channel_id:'',        //渠道编号
        state:'',        //状态
        not_allow_method:[],  //不允许的方法
      };
    },
    //dialog确实按钮事件
    onGoon(formName){
      if(this.addLoading) return;
      let {addType} = this;
      let {id,member_id,pay_type,channel_id,state,not_allow_method} = this.addObj;
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let params = {
            channel_id,
            not_allow_method:JSON.stringify(not_allow_method),
            state
          }
          let func = addMerchant;
          if(addType == "edit"){
            params.id = id;
            func = editMerchant;
          }else{
            params.pay_type = pay_type;
            params.member_id = member_id
          }
          this.addLoading = true;
          const res = await func(params).catch(e => this.addLoading = false)
          this.addLoading = false;
          if(res.code != 200) return;
          this.dialogShow = false;
          this.getList();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //输入框 检索
    async remoteMethod(val) {
      let pType = {
        0:'account',
        1:'mobile',
        2:'dname',
      }
      let params = {
        page_size: 100
      }
      params[pType[this.memberType]] = val;
      this.selectLoading = true;
      const res = await queryMemberByCondition(params).catch(e => e).finally(() => {this.selectLoading = false})
      this.selectLoading = false;
      if(res.code != 200) return;
      this.memberList = res.data
    },
  }
}
</script>
<style scoped lang="scss">
@import "@/common/style.scss";
.supplierConfig.page {
  overflow-y: auto;
  .wrap {
    min-width: 1182px;
    min-height: 740px;
    border-radius: 2px;
    background: #ffffff;
    padding: 16px 16px 50px 16px;
    .creatTG {
      display: inline-block;
      width: 98px;
      height: 32px;
      background: #4D7EFA;
      border-radius: 2px;
      color: #FFFFFF;
      margin-bottom: 20px;
      cursor: pointer;
    }
    .searchBox {
      .space{
        margin:0 12px 12px 0;
      }
      .reset {
        margin:0 15px 0 15px;
        text-align: center;
        width: 56px;
        height: 32px;
        border-radius: 2px;
        color: #565A66;
        border: 1px solid #DCDFE6;
        cursor: pointer;
      }
      .search {
        text-align: center;
        width: 56px;
        height: 32px;
        background: #4D7EFA;
        border-radius: 2px;
        color: #FFFFFF;
        cursor: pointer;
      }
    }
    .tableList {
      margin-top:15px;
      .page-box {
        margin-top: 17px;
        text-align: right;
      }
      .arrowMore{
        margin-right:12px;
        fill: #818899;
      }
    }
  }
  .dialog {
    height: auto;
    .dialogList {
      max-height: 500px;
      padding: 32px 70px 40px 70px;
      .tit {
        margin-top: 25px;
        .serial {
          width: 24px;
          height: 24px;
          background: #F8A201;
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #FFFFFF;
          border-radius: 50%;
        }
        .text {
          padding-left: 8px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #565A66;
          line-height: 22px;
          .goLogin {
            display: inline-block;
            width: 70px;
            height: 22px;
            background: #4D7EFA;
            border-radius: 2px;
            color: #FFFFFF;
            line-height: 22px;
            text-align: center;
          }
        }
      }
      img {
        width: 100%;
        margin-top: 8px;
        margin-bottom: 24px;
      }
    }
    .dialog-footer {
      border-top: 1px solid #EBEEF5;
      height: 55px;
      padding: 0 16px;
      .dfcancle {
        margin-right: 8px;
        width: 74px;
        height: 32px;
        background: #FFFFFF;
        border-radius: 2px;
        border: 1px solid #DCDFE6;
        font-size: 14px;
        font-weight: 400;
        color: #565A66;
      }
      .dfconfirm {
        width: 74px;
        height: 32px;
        background: #4D7EFA;
        border-radius: 2px;
        border: 1px solid #DCDFE6;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
      }
    }
  }
}
</style>