<template>
    <div class="page limitConfig">
        <page-header :bread-crumb="true" :back-button="true" :sync="true" />
        <div class="wrap">
            <div class="funcWrap">
                <div class="menu-item" v-for="item in authList" :key="item.title" style="margin:30px 0 0 0">
                    <div style="line-height:38px;">{{item.title}}</div>
                    <div>
                        <el-checkbox style="height:28px;" @change="inputChecked(authItem)" :checked="authItem.is_auth == 1" v-for="authItem in item.list"  :label="authItem.uri" :key="authItem.uri" >{{authItem.title}}</el-checkbox>
                    </div>
                </div>
            </div>
            <div class="footerBar">
                <button @click="saveEvent" class="dfconfirm">保存</button>
            </div>
        </div>
    </div>
</template>

<script>
    import {getMemBerAuthList,editAuth} from "@/api/config"
    import { Message } from 'element-ui';
    export default {
        name: "limitConfig",
        data() {
            return {
                authList:[],
            }
        },
        mounted() {
            this.member_id = this.$route.query.member_id || '';
            this.account = this.$route.query.account || '';
            this.init();
        },
        methods: {
            init(){
                this.getMemBerAuthList();
            },
            async getMemBerAuthList(){
                let params = {
                    member_id:this.member_id
                }
                const res = await getMemBerAuthList(params).catch(e => e);
                if(res.code != 200) return;
                this.authList = JSON.parse(JSON.stringify(res.data)) || [];
            },
            //复选框 状态切换
		    inputChecked(val){
			    if(val.is_auth == 1){
				    val.is_auth =0
			    }else{
				    val.is_auth = 1
			    }
            },
            //保存事件
            async saveEvent(){
                let uriArray = [];
                this.authList.forEach(item => {
                    item.list.forEach(childItem => {
                        if(childItem.is_auth == 1) uriArray.push(childItem.uri)
                    })
                })
                let uris = uriArray.join(',');
                let params = {
                    member_id:this.member_id,
                    uris,
                    account:this.account
                }
                const res = await editAuth(params).catch(e => e)
                if(res.code != 200) return;
                Message.success('保存成功');
                this.$router.replace({
                    name:'userLimitConfig'
                })
            },
        }
    }
</script>
<style scoped lang="scss">
    @import "@/common/style.scss";
    .limitConfig.page {
        overflow-y: auto;
        .wrap {
            min-width: 1182px;
            min-height: 740px;
            border-radius: 2px;
            background: #ffffff;
            padding: 16px 16px 50px 16px;
            .funcWrap {
                padding:0 0 0 60px;
                margin: 0 0 50px 0;

            }
            .footerBar {
                padding: 10px 0 0 80px;
                .dfcancle {
                    width: 74px;
                    height: 32px;
                    background: #ffffff;
                    border-radius: 2px;
                    border: 1px solid #dcdfe6;
                    font-size: 14px;
                    font-weight: 400;
                    color: #565a66;
                }
                .dfconfirm {
                    margin-right: 16px;
                    width: 74px;
                    height: 32px;
                    background: #4d7efa;
                    border-radius: 2px;
                    border: 1px solid #dcdfe6;
                    font-size: 14px;
                    font-weight: 400;
                    color: #ffffff;
                }
            }
        }
    }
</style>