<template>
    <div class="page employeeConfig">
        <page-header :bread-crumb="true" :back-button="true" :sync="true" />
        <div class="wrap">
            <el-tabs v-model="activeName">
                <el-tab-pane label="微信支付子商户管理" name="wechat" lazy>
                    <wechat-employee-config></wechat-employee-config>
                </el-tab-pane>
                <el-tab-pane label="支付宝授权商户列表" name="alipay" lazy>
                    <alipay-employee-config></alipay-employee-config>
                </el-tab-pane>
                <el-tab-pane label="百度渠道" name="baidu" lazy>
                    <baidu-employee-config></baidu-employee-config>
                </el-tab-pane>
                <el-tab-pane label="其他渠道" name="others" lazy>
                    <others-employee-config></others-employee-config>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import {
    getMerchantWePayList,
    setMerchantIndependent,
    editMerchantWePay,
    addMerchantWePay,
    getMerchantAliPayList,
} from "@/api/config";
import { queryMemberByCondition } from "@/api/admin";
import { Message } from "element-ui";
import WechatEmployeeConfig from "./wechatEmployeeConfig.vue";
import AlipayEmployeeConfig from "./alipayEmployeeConfig.vue";
import BaiduEmployeeConfig from "./baiduEmployeeConfig.vue";
import OthersEmployeeConfig from "./othersEmployeeConfig.vue";
export default {
    name: "employeeConfig",
    components: {
        WechatEmployeeConfig,
        AlipayEmployeeConfig,
        BaiduEmployeeConfig,
        OthersEmployeeConfig,
    },
    data() {
        return {
            wechatAddObj: {
                id: "",
                member_id: "", // 供应商 id
                sub_mch_id: "", // 子商户号
                sub_appid: "", //公众号 appid 或小程序 appid
                use_env: "", //应用环境:0 线下,1 微商城,2 线下+微商城,3 小程序,100 所有可独立收款的渠道
            },
            wechatPage: 1,
            wechatPage_size: 10,
            wechatTotal: 0,
            alipayPage: 1,
            alipayPage_size: 10,
            alipayTotal: 0,
            activeName: "wechat", //wechat == 微信支付子商户管理  alipay == 支付宝授权商户列表
            wechatSearchBox: {
                use_env: "3", //应用环境
                member_id: "", //用户id
            },
            alipaySearchBox: {
                member_id: "", //用户id
            },
            //应用环境列表
            use_env_box: [
                {
                    value: "0",
                    label: "线下",
                },
                {
                    value: "1",
                    label: "微商城",
                },
                {
                    value: "2",
                    label: "线下+微商城",
                },
                {
                    value: "3",
                    label: "小程序",
                },
                {
                    value: 100,
                    label: "所有可独立收款的渠道",
                },
            ],
            memberList: [],
            memberTypeList: [
                {
                    value: 0,
                    label: "账号",
                },
                {
                    value: 1,
                    label: "手机号",
                },
                {
                    value: 2,
                    label: "名称",
                },
            ],
            memberType: 0,
            wechatGetType: "all", //all==所有 search==搜索
            alipayGetType: "all",
            selectLoading: false,
            wechatLoading: false,
            alipayLoading: false,
            wechatTableData: [],
            alipayTableData: [],
            wechatDialogShow: false,
            wechatAddType: "add", //
            rules: {
                member_id: [
                    {
                        required: true,
                        message: "供应商id不能为空",
                        trigger: "blur",
                    },
                ],
                sub_mch_id: [
                    {
                        required: true,
                        message: "子商户号不能为空",
                        trigger: "blur",
                    },
                ],
                sub_appid: [
                    {
                        required: true,
                        message: "appid不能为空",
                        trigger: "blur",
                    },
                ],
                use_env: [
                    {
                        required: true,
                        message: "应用环境不能为空",
                        trigger: "blur",
                    },
                ],
            },
            wechatAddLoading: false,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            // this.getWechatList();
            // this.getAlipayList();
        },
        async getWechatList() {
            let { wechatPage, wechatPage_size } = this;
            let { use_env, member_id } = this.wechatSearchBox;
            let params = {
                page: wechatPage,
                page_size: wechatPage_size,
                use_env,
            };
            if (this.wechatGetType == "search") {
                params.member_id = member_id;
            }
            this.wechatLoading = true;
            const res = await getMerchantWePayList(params)
                .catch((e) => e)
                .finally(() => {
                    this.wechatLoading = false;
                });
            this.wechatLoading = false;
            if (res.code != 200) return;
            let { list, sum } = res.data;
            this.wechatTableData = list || [];
            this.wechatTotal = parseInt(sum);
        },
        async getAlipayList() {
            let { alipayPage, alipayPage_size } = this;
            let { member_id } = this.alipaySearchBox;
            let params = {
                page: alipayPage,
                page_size: alipayPage_size,
            };
            if (this.alipayGetType == "search") {
                params.member_id = member_id;
            }
            this.alipayLoading = true;
            const res = await getMerchantAliPayList(params)
                .catch((e) => (this.alipayLoading = false))
                .finally(() => (this.alipayLoading = false));
            this.alipayLoading = false;
            if (res.code != 200) return;
            let { list, sum } = res.data;
            this.alipayTableData = list || [];
            this.alipayTotal = parseInt(sum);
        },
        //输入框 检索
        async remoteMethod(val) {
            let pType = {
                0: "account",
                1: "mobile",
                2: "dname",
            };
            let params = {
                page_size: 100,
            };
            params[pType[this.memberType]] = val;
            this.selectLoading = true;
            const res = await queryMemberByCondition(params)
                .catch((e) => e)
                .finally(() => {
                    this.selectLoading = false;
                });
            this.selectLoading = false;
            if (res.code != 200) return;
            this.memberList = res.data;
        },
        //重置事件
        resetEvent(type = "wechat") {
            if (type == "wechat") {
                (this.wechatSearchBox = {
                    use_env: "",
                    member_id: "",
                }),
                    (this.wechatGetType = "all");
                this.wechatPage = 1;
                this.getWechatList();
            } else {
                (this.alipaySearchBox = {
                    member_id: "", //用户id
                }),
                    (this.alipayGetType = "all");
                this.alipayPage = 1;
                this.getAlipayList();
            }
        },
        //搜索事件
        searchEvent(type = "wechat") {
            if (type == "wechat") {
                let { use_env, member_id } = this.wechatSearchBox;
                if (!use_env && !member_id)
                    return Message.error("请输入搜索条件");
                this.wechatGetType = "search";
                this.wechatPage = 1;
                this.getWechatList();
            } else {
                let { member_id } = this.alipaySearchBox;
                if (!member_id) return Message.error("请输入搜索条件");
                this.alipayGetType = "search";
                this.alipayPage = 1;
                this.getAlipayList();
            }
        },
        handleCurrentChange(val, e) {
            if (e == "wechat") {
                if (this.wechatPage == val) return;
                this.wechatPage = val;
                this.getWechatList();
            } else {
                if (this.alipayPage == val) return;
                this.alipayPage = val;
                this.getAlipayList();
            }
        },
        //新增微信支付配置
        addEvent() {
            this.wechatDialogShow = true;
            this.wechatAddType = "add";
        },
        async statusChange(val) {
            let text = val.status == 1 ? "关闭独立收款" : "开启独立收款";
            const result = await this.$helper.default.message
                .confirm({
                    title: `提示`,
                    content: text,
                })
                .catch((e) => e);
            if (result == "cancel") return;
            let params = {
                id: val.id,
            };
            params.status = val.status == 1 ? 0 : 1;
            const res = await setMerchantIndependent(params).catch((e) => e);
            this.getWechatList();
        },
        //
        onLeave() {
            this.wechatDialogShow = false;
            this.wechatAddObj = {
                id: "",
                member_id: "", // 供应商 id
                sub_mch_id: "", // 子商户号
                sub_appid: "", //公众号 appid 或小程序 appid
                use_env: "", //应用环境:0 线下,1 微商城,2 线下+微商城,3 小程序,100 所有可独立收款的渠道
            };
        },
        //dialog确实按钮事件
        onGoon(formName) {
            if (this.wechatAddLoading) return;
            let { wechatAddType } = this;
            let { id, member_id, sub_mch_id, sub_appid, use_env } =
                this.wechatAddObj;
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    let func = addMerchantWePay;
                    let params = {
                        sub_mch_id,
                        sub_appid,
                        use_env,
                    };
                    if (wechatAddType == "edit") {
                        params.id = id;
                        func = editMerchantWePay;
                    } else {
                        params.member_id = member_id;
                        params.name = this.memberList[member_id].dname;
                    }
                    this.wechatAddLoading = true;
                    const res = await func(params)
                        .catch((e) => (this.wechatAddLoading = false))
                        .finally(() => (this.wechatAddLoading = false));
                    this.wechatAddLoading = false;
                    if (res.code != 200) return;
                    this.onLeave();
                    this.getWechatList();
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        //微信支付配置编辑
        editWechat(val) {
            let { id, sub_merchant_id, sub_appid, use_env } = val;
            this.wechatAddObj.id = id;
            this.wechatAddObj.sub_mch_id = sub_merchant_id;
            this.wechatAddObj.sub_appid = sub_appid;
            this.wechatAddObj.use_env = use_env;
            this.wechatDialogShow = true;
            this.wechatAddType = "edit";
        },
    },
};
</script>

<style lang="scss">
@import "@/common/style.scss";
.employeeConfig.page {
    overflow-y: auto;
    .wrap {
        min-width: 1182px;
        min-height: 740px;
        border-radius: 2px;
        background: #ffffff;
        padding: 16px 16px 50px 16px;
        .creatTG {
            display: inline-block;
            width: 98px;
            height: 32px;
            background: #4d7efa;
            border-radius: 2px;
            color: #ffffff;
            margin-bottom: 10px;
            cursor: pointer;
        }
        .searchBox {
            .space {
                margin: 0 12px 12px 0;
            }
            .reset {
                margin: 0 15px 0 15px;
                text-align: center;
                width: 56px;
                height: 32px;
                border-radius: 2px;
                color: #565a66;
                border: 1px solid #dcdfe6;
                cursor: pointer;
            }
            .search {
                text-align: center;
                width: 56px;
                height: 32px;
                background: #4d7efa;
                border-radius: 2px;
                color: #ffffff;
                cursor: pointer;
            }
        }
        .tableList {
            margin-top: 15px;
            .page-box {
                margin-top: 17px;
                text-align: right;
            }
            .arrowMore {
                margin-right: 12px;
                fill: #818899;
            }
        }
    }
    .dialog {
        height: auto;
        .dialogList {
            max-height: 500px;
            padding: 32px 70px 40px 70px;
            overflow: auto;
            .tit {
                margin-top: 25px;
                .serial {
                    width: 24px;
                    height: 24px;
                    background: #f8a201;
                    font-size: 14px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #ffffff;
                    border-radius: 50%;
                }
                .text {
                    padding-left: 8px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #565a66;
                    line-height: 22px;
                    .goLogin {
                        display: inline-block;
                        width: 70px;
                        height: 22px;
                        background: #4d7efa;
                        border-radius: 2px;
                        color: #ffffff;
                        line-height: 22px;
                        text-align: center;
                    }
                }
            }
            img {
                width: 100%;
                margin-top: 8px;
                margin-bottom: 24px;
            }
        }
        .dialog-footer {
            border-top: 1px solid #ebeef5;
            height: 55px;
            padding: 0 16px;
            .dfcancle {
                margin-right: 8px;
                width: 74px;
                height: 32px;
                background: #ffffff;
                border-radius: 2px;
                border: 1px solid #dcdfe6;
                font-size: 14px;
                font-weight: 400;
                color: #565a66;
            }
            .dfconfirm {
                width: 74px;
                height: 32px;
                background: #4d7efa;
                border-radius: 2px;
                border: 1px solid #dcdfe6;
                font-size: 14px;
                font-weight: 400;
                color: #ffffff;
            }
        }
    }
}
</style>
