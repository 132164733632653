<template>
    <div class="page userLimitConfig">
        <page-header :bread-crumb="true" :back-button="true" :sync="true" />
        <div class="wrap">
             <button class="creatTG f14w400" @click="addEvent"><i class="el-icon-plus"></i>新增用户</button>
             <div class="searchBox">
                <el-input class="space" v-model="searchBox.member_id" size="small" style="width:180px;" placeholder="请输入会员id"></el-input>
                <el-input class="space" v-model="searchBox.account" size="small" style="width:160px;" placeholder="请输入会员账号"></el-input>
                <button class="reset" @click="resetEvent">重置</button>
                <button class="search f14h20w400" @click="searchEvent">搜索</button>
            </div>
            <div class="tableList">
                <!-- 表格部分 -->
                <el-table v-loading="loading" :data="tableData" style="width: 100%" class="table-box" max-height="645" :header-cell-style="{background:'#F5F5F5',color:'#606266'}">
                    <el-table-column prop="account" label="会员账号" min-width="120">
                    </el-table-column>
                    <el-table-column prop="member_id" label="会员id" min-width="120">
                    </el-table-column>
                    <el-table-column prop="create_time" label="创建时间" min-width="180">
                        <template slot-scope="scope">{{scope.row.create_time | $unixTimeToDateTime}}</template>  
                    </el-table-column>
                    <el-table-column prop="update_time" label="更新时间" min-width="180">
                        <template slot-scope="scope">{{scope.row.update_time | $unixTimeToDateTime}}</template>
                    </el-table-column>
                     <el-table-column  label="操作"  align="center" min-width="120">
                        <template slot-scope="scope">
                            <el-button
                            type="text"
                            style="padding: 0;color: #4D7EFA;"
                            class="f14h20w400"
                            @click="editLimit(scope.row)"
                            >编辑用户权限</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 分页 -->
                <div class="page-box ">
                    <el-pagination @current-change="handleCurrentChange" :current-page="page" :page-size="page_size" layout="total, prev, pager, next, jumper" :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
        <el-dialog :visible.sync="dialogShow" :show-close="false" v-if="dialogShow" width="560px" clearable="true" :close-on-click-modal="false">
            <div class="dialog">
                <dialogHeader title="新增用户" @closeDialog="onLeave"></dialogHeader>
                <div class="dialogList" >
                    <div style="margin-bottom:8px;color:red;">需权限配置成功，才算新增用户</div>
                    <el-select size="small" v-model="memberType" placeholder="请选择" style="width:120px;">
                        <el-option v-for="item in memberTypeList" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-select class="space" size="small" v-model="new_member_id" filterable remote reserve-keyword :placeholder="`请输入${memberTypeList[memberType].label}搜索用户`" :remote-method="remoteMethod" :loading="selectLoading" style="width:260px;">
                        <el-option v-for="item in memberList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div slot="footer" class="dialog-footer" flex="cross:center main:right">
                    <button @click="onGoon" class="dfconfirm">配置权限</button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import {getAuthList,editAuth} from "@/api/config"
    import { queryMemberByConditionByZt, }  from "@/api/admin"
    import { Message } from 'element-ui';
    export default {
        name: "userLimitConfig",
        data() {
            return {
                searchBox:{
                    member_id:'',    //会员 id
                    account:'',      //会员账号
                },
                loading:false,
                tableData:[],
                page:1,
                page_size:10,
                total:0,
                getType:'all',   //all==所有 search==搜索 
                dialogShow:false,
                memberTypeList: [{
                        value: 0,
                        label: '账号'
                    },
                    {
                        value: 1,
                        label: '手机号'
                    },
                    {
                        value: 2,
                        label: '名称'
                    },
                ],
                memberType:0,
                memberList:[],
                selectLoading:false,
                new_member_id:''
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            init(){
                this.getList();
            },
            async getList(){
                let {page,page_size} = this;
                let params = {
                    page,
                    page_size
                }
                if(this.getType == 'search'){
                    params = Object.assign(params,this.searchBox)
                }
                this.loading = true;
                const res = await getAuthList(params).catch(e => this.loading = false).finally( () => {this.loading = false});
                this.loading = false;
                if(res.code != 200) return;
                let {list,total} = res.data;
                this.tableData = list;
                this.total = total;
            },
            //新增事件
            addEvent(){
                this.dialogShow = true;
                this.new_member_id = '';
            },
            //重置事件
            resetEvent(){
                this.searchBox = {
                    member_id:'',    //会员 id
                    account:'',      //会员账号
                },
                this.getType = 'all';
                this.page = 1;
                this.getList(); 
            },
            //搜索事件
            searchEvent(){
                this.getType = 'search';
                this.page = 1;
                this.getList();
            },
            //page改变 促发事件
            handleCurrentChange(val){
                if(this.page == val) return;
                this.page = val;
                this.getList();
            },
            //权限编辑
            editLimit(val){
               
                this.$router.push({
                    name:'limitConfig',
                    query:{
                        member_id:val.member_id,
                        account:val.account
                    }
                })
            },
            //关闭弹窗事件
            onLeave(){
                this.dialogShow = false; 
            },
            //输入框 检索
            async remoteMethod(val) {
                let pType = {
                    0:'accounts',
                    1:'mobile',
                    2:'name',
                }
                let params = {
                    // page_size: 100
                }
                if(this.memberType == 0) {
                    params[pType[this.memberType]] = [val];
                } else {
                    params[pType[this.memberType]] = val;
                }
                this.selectLoading = true;
                const res = await queryMemberByConditionByZt(params).catch(e => e).finally(() => {this.selectLoading = false})
                this.selectLoading = false;
                if(res.code != 200) return;
                this.memberList = res.data
            },
            //
            onGoon(){
                if(!this.new_member_id) return Message.error('请先选择用户')
                // let memberInfo = this.memberList[this.new_member_id];
                let memberInfo = this.memberList.find(v => v.id == this.new_member_id);
                this.onLeave()
                this.$router.push({
                    name:'limitConfig',
                    query:{
                        member_id:this.new_member_id,
                        account:memberInfo && memberInfo.account
                    }
                })
            },
        }
    }
</script>
<style scoped lang="scss">
    @import "@/common/style.scss";
    .userLimitConfig.page {
        overflow-y: auto;
        .wrap {
            min-width: 1182px;
            min-height: 740px;
            border-radius: 2px;
            background: #ffffff;
            padding: 16px 16px 50px 16px;
            .creatTG {
                display: inline-block;
                width: 98px;
                height: 32px;
                background: #4D7EFA;
                border-radius: 2px;
                color: #FFFFFF;
                margin-bottom: 20px;
                cursor: pointer;
            }
            .searchBox {
                .space{
                    margin:0 12px 12px 0;
                }
                .reset {
                    margin:0 15px 0 15px;
                    text-align: center;
                    width: 56px;
                    height: 32px;
                    border-radius: 2px;
                    color: #565A66;
                    border: 1px solid #DCDFE6;
                    cursor: pointer;
                }
                .search {
                    text-align: center;
                    width: 56px;
                    height: 32px;
                    background: #4D7EFA;
                    border-radius: 2px;
                    color: #FFFFFF;
                    cursor: pointer;
                }
            }
            .tableList {
                margin-top:15px;
                .page-box {
                    margin-top: 17px;
                    text-align: right;
                }
                .arrowMore{
                    margin-right:12px;
                    fill: #818899;
                }
            }
        }
        .dialog {
            height: auto;
            .dialogList {
                max-height: 500px;
                overflow-y: scroll;
                padding: 32px 70px 40px 70px;
                .tit {
                    margin-top: 25px;
                    .serial {
                        width: 24px;
                        height: 24px;
                        background: #F8A201;
                        font-size: 14px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #FFFFFF;
                        border-radius: 50%;
                    }
                    .text {
                        padding-left: 8px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #565A66;
                        line-height: 22px;
                        .goLogin {
                            display: inline-block;
                            width: 70px;
                            height: 22px;
                            background: #4D7EFA;
                            border-radius: 2px;
                            color: #FFFFFF;
                            line-height: 22px;
                            text-align: center;
                        }
                    }
                }
                img {
                    width: 100%;
                    margin-top: 8px;
                    margin-bottom: 24px;
                }
            }
            .dialog-footer {
                border-top: 1px solid #EBEEF5;
                height: 55px;
                padding: 0 16px;
                .dfcancle {
                    margin-right: 8px;
                    width: 74px;
                    height: 32px;
                    background: #FFFFFF;
                    border-radius: 2px;
                    border: 1px solid #DCDFE6;
                    font-size: 14px;
                    font-weight: 400;
                    color: #565A66;
                }
                .dfconfirm {
                    width: 74px;
                    height: 32px;
                    background: #4D7EFA;
                    border-radius: 2px;
                    border: 1px solid #DCDFE6;
                    font-size: 14px;
                    font-weight: 400;
                    color: #FFFFFF;
                }
            }
        }
    }
</style>