<template>
    <el-dialog
        v-if="visible"
        :visible="visible"
        :show-close="false"
        width="660px"
        :close-on-click-modal="false"
    >
        <div class="dialog">
            <dialogHeader
                :title="actionType == 'add' ? '新增配置' : '编辑配置'"
                @closeDialog="onClose"
            ></dialogHeader>
            <div class="dialogList">
                <el-form
                    :rules="rules"
                    ref="dialogForm"
                    :model="dialogForm"
                    label-width="125px"
                >
                    <el-form-item
                        label="供应商"
                        prop="member_id"
                        v-if="actionType == 'add'"
                    >
                        <el-select
                            v-model="memberType"
                            placeholder="请选择"
                            style="width: 120px"
                            size="small"
                        >
                            <el-option
                                v-for="item in memberTypeList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                        <el-select
                            size="small"
                            v-model="dialogForm.member_id"
                            filterable
                            remote
                            reserve-keyword
                            :placeholder="`请输入${memberTypeList[memberType].label}搜索用户`"
                            :remote-method="remoteMethod"
                            :loading="selectLoading"
                            style="width: 265px"
                        >
                            <el-option
                                v-for="item in memberList"
                                :key="item.id"
                                :label="item.dname"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        v-if="actionType == 'add'"
                        label="渠道"
                        prop="channel_id"
                    >
                        <el-select
                            v-model="dialogForm.channel_id"
                            :disabled="isFetchingTemplate"
                            @change="onChannelChange"
                            placeholder="请选择渠道"
                            size="small"
                            style="width: 100%"
                        >
                            <el-option
                                v-for="item in channelList"
                                :key="item.id"
                                :label="item.channel_name"
                                :value="item.channel_id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="渠道用户" prop="channel_member_id">
                        <el-input
                            size="small"
                            v-model.trim="dialogForm.channel_member_id"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="模式选择">
                        <el-radio-group v-model="mode" @change="onModeChange" size="small">
                            <el-radio-button :label="0">代码</el-radio-button>
                            <el-radio-button :label="1" :disabled="modeDisabled">表单</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    <!-- 代码模式 -->
                    <el-form-item v-if="!mode" label="配置信息" prop="detail">
                        <el-input
                            size="small"
                            type="textarea"
                            :autosize="{ minRows: 5, maxRows: 8}"
                            v-model.trim="dialogForm.detail"
                        ></el-input>
                    </el-form-item>
                    <!-- 表单模式 -->
                    <template v-else>
                        <el-form-item v-for="(formItem, _key) in configTemplate" :label="formItem.name" :prop="_key" :key="_key">
                            <el-input v-if="formItem.type === 'text'" v-model.trim="dialogForm[_key]" size="small"></el-input>
                            <el-input
                                v-if="formItem.type === 'textarea'"
                                size="small"
                                type="textarea"
                                :autosize="{ minRows: 5, maxRows: 8}"
                                v-model.trim="dialogForm[_key]"
                            ></el-input>
                            <!-- 光鹏：不限制数字类型最大最小值，不会有小数点 -->
                            <el-input-number v-if="formItem.type === 'number'" v-model="dialogForm[_key]" :precision="formItem.precision||0" size="small"></el-input-number>
                            <p v-if="formItem.tip" class="dialogForm-tip">{{ formItem.tip }}</p>
                        </el-form-item>
                    </template>
                </el-form>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
                flex="cross:center main:right"
            >
                <el-button size="small" @click="onClose">取 消</el-button>
                <el-button
                    size="small"
                    type="primary"
                    :loading="addLoading || isFetchingTemplate"
                    @click="onSumbit('dialogForm')"
                >
                    确 定
                </el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import * as initStore from "@/store/init.js";
import { addCommonConfig, updateCommonConfig, getChannelConfigTemplate } from "@/api/config";
import { queryMemberByCondition } from "@/api/admin";

export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        actionType: {
            type: String,
            default: "add",
        },
        configData: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            rules: {
                member_id: [
                    {
                        required: true,
                        message: "供应商id不能为空",
                        trigger: "blur",
                    },
                ],
                channel_id: [
                    {
                        required: true,
                        message: "渠道不能为空",
                        trigger: "blur",
                    },
                ],
                channel_member_id: [
                    {
                        required: true,
                        message: "渠道用户不能为空",
                        trigger: "blur",
                    },
                ],
                detail: [
                    {
                        required: true,
                        message: "配置信息不能为空",
                        trigger: "blur",
                    },
                ],
            },

            mode: 0,
            configTemplate: {},
            isFetchingTemplate: false,

            dialogForm: {
                id: "",
                channel_id: "",
                channel_member_id: "",
                member_id: "", // 会员id
                detail: "", // 配置信息
            },
            selectLoading: false,
            memberTypeList: [
                {
                    value: 0,
                    label: "账号",
                },
                {
                    value: 1,
                    label: "手机号",
                },
                {
                    value: 2,
                    label: "名称",
                },
            ],
            memberType: 0, // 用户搜索类型
            memberList: [], // 用户列表
            addLoading: false,
        };
    },
    computed: {
        channelList: () => initStore.state.channelList, //渠道列表

        modeDisabled() {
            return !Object.values(this.configTemplate).length;
        },
    },
    watch: {
        visible(val) {
            if (val) {
                if (this.actionType === "edit" && this.configData) {
                    let { id, detail_config, channel_merchant_id } =
                        this.configData;
                    console.log(this.configData);
                    this.dialogForm.id = id;
                    this.dialogForm.channel_member_id = channel_merchant_id;
                    this.dialogForm.detail = detail_config;
                }
                if( this.actionType === "edit" ) {
                    this.configData && this.configData.channel_id && this.getChannelConfigTemplate(this.configData.channel_id);
                }
            } else {
                this.dialogForm = {
                    id: "",
                    channel_id: "",
                    channel_member_id: "",
                    member_id: "", // 会员id
                    detail: "", // 配置信息
                };
                this.mode = 0;
                this.configTemplate = {};
                this.isFetchingTemplate = false;
            }
        },
    },
    methods: {
        async getChannelConfigTemplate(channel_id) {
            if(this.isFetchingTemplate) return;
            this.isFetchingTemplate = true;

            const res = await getChannelConfigTemplate({channel_id}).catch(e => e);

            this.isFetchingTemplate = false;
            if( !res || res.code != 200 ) return;// this.$message.error(res && (res.msg || res.message) || '获取支付渠道配置模板失败：服务器响应为空');
            if( res.data && Object.keys(res.data).length ) {
                this.configTemplate = res.data;
                this.mode = 1;

                const {detail} = this.dialogForm;
                let o = {};
                try {
                    console.log(detail);
                    if( detail ) {
                        o = JSON.parse(detail);
                    }
                } catch(e) {
                    this.$message.error('配置信息格式错误:' + e.toString());
                }

                Object.keys(res.data).forEach(key => {
                    const {required, name, type, default: defaultValue} = res.data[key];

                    this.$set(this.dialogForm, key, type == 'number' ? +o[key] || defaultValue || 0 : o[key] || defaultValue || '');
                    this.$set(this.rules, key, [
                        {required, message: `请输入${name}`, trigger: ['blur', 'submit']}
                    ]);
                });
            }
        },

        onModeChange(mode) {
            const {configTemplate, dialogForm} = this;
            const {detail} = dialogForm;

            switch(mode) {
                case 0: {
                    const o = Object.keys(configTemplate).reduce((o, key) => {
                        return {...o, [key]: dialogForm[key]}
                    }, {});

                    this.$set(this.dialogForm, 'detail', JSON.stringify(o));
                }

                case 1: {
                    let o = {};

                    try {
                        if( detail ) {
                            o = JSON.parse(detail);
                        }
                    } catch(e) {
                        this.$message.error('配置信息格式错误:' + e.toString());
                    }
                    
                    Object.keys(configTemplate).forEach(key => {
                        this.$set(this.dialogForm, key, o[key] || '');
                    });
                }
            }
        },

        onChannelChange(channel_id) {
            this.configTemplate = {};
            this.mode =  0;
            if(channel_id) {
                this.getChannelConfigTemplate(channel_id);
            }
        },

        async remoteMethod(val) {
            let pType = {
                0: "account",
                1: "mobile",
                2: "dname",
            };
            let params = {
                page_size: 100,
                [pType[this.memberType]]: val,
            };
            this.selectLoading = true;
            const res = await queryMemberByCondition(params);
            this.selectLoading = false;
            if (res.code != 200) return;
            this.memberList = res.data;
        },
        onClose() {
            this.$emit("update:visible", false);
        },
        onSumbit(formName) {
            if (this.addLoading) return;
            let { actionType, configTemplate, mode, dialogForm } = this;
            let { id, member_id, channel_id, channel_member_id, detail } =
                this.dialogForm;
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    let func = addCommonConfig;
                    let params = {
                        channel_member_id,
                        detail,
                    };
                    if (actionType == "edit") {
                        params.id = id;
                        func = updateCommonConfig;
                    } else {
                        params.member_id = member_id;
                        params.channel_id = channel_id;
                    }
                    if( mode == 1 ) {
                        const o = Object.keys(configTemplate).reduce((o, key) => {
                            return {...o, [key]: dialogForm[key]}
                        }, {});

                        params.detail = JSON.stringify(o);
                    }

                    this.addLoading = true;
                    const res = await func(params).catch((e) => {
                        this.addLoading = false;
                    });
                    this.addLoading = false;
                    if (res.code != 200) return;
                    this.onClose();
                    this.$emit("refresh");
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
    },
};
</script>

<style>
.dialogForm-tip {
    color: #909399;
}
</style>
