<template>
    <div class="othersEmployeeConfig">
        <el-button
            size="small"
            type="primary"
            icon="el-icon-plus"
            @click="addEvent"
            style="margin-bottom: 10px"
        >
            新增配置
        </el-button>
        <filter-box
            channel="others"
            @reset="resetEvent"
            @search="searchEvent"
        ></filter-box>
        <div class="tableList">
            <!-- 表格部分 -->
            <el-table
                v-loading="tableLoading"
                :data="tableData"
                style="width: 100%"
                class="table-box"
                :header-cell-style="{
                    background: '#F5F5F5',
                    color: '#606266',
                }"
            >
                <el-table-column
                    prop="member_id"
                    label="会员ID"
                    min-width="100"
                >
                </el-table-column>
                <el-table-column prop="channel_id" label="渠道" min-width="100">
                    <template slot-scope="{ row }">
                        <span>{{ getChannelName(row.channel_id) }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="channel_merchant_id"
                    label="渠道用户"
                    min-width="100"
                >
                </el-table-column>
                <el-table-column label="详细配置" min-width="300">
                    <template slot-scope="{ row }">
                        <el-tooltip
                            popper-class="detailPopper"
                            effect="dark"
                            :content="row.detail_config"
                            placement="top"
                        >
                            <div
                                style="
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                "
                            >
                                <span style="white-space: nowrap">
                                    {{ row.detail_config }}
                                </span>
                            </div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="create_time"
                    label="创建日期"
                    min-width="100"
                >
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            style="padding: 0; color: #4d7efa"
                            class="f14h20w400"
                            @click="editEvent(scope.row)"
                        >
                            编辑
                        </el-button>
                        <el-button
                            type="text"
                            style="padding: 0; color: #4d7efa"
                            class="f14h20w400"
                            @click="delEvent(scope.row)"
                        >
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="page-box">
                <el-pagination
                    @current-change="fetchList"
                    :current-page.sync="page"
                    :page-size.sync="pageSize"
                    layout="total, prev, pager, next, jumper"
                    :total="total"
                >
                </el-pagination>
            </div>
        </div>
        <others-dialog
            :visible.sync="dialogVisible"
            :actionType="dialogType"
            :configData="currentRow"
            @refresh="refreshList"
        ></others-dialog>
    </div>
</template>

<script>
import { MessageBox } from "element-ui";
import { getCommonConfigList, delCommonConfig } from "@/api/config";
import * as initStore from "@/store/init.js";
import FilterBox from "./components/filterBox.vue";
import OthersDialog from "./components/othersDialog.vue";

export default {
    components: {
        FilterBox,
        OthersDialog,
    },
    data() {
        return {
            page: 1,
            pageSize: 10,
            total: 0,
            tableLoading: false,
            tableData: [],
            searchParams: {
                member_id: "",
            },
            dialogVisible: false,
            dialogType: "add",
            currentRow: null,
        };
    },
    computed: {
        channelList: () => initStore.state.channelList, //渠道列表
    },
    mounted() {
        this.fetchList();
    },
    methods: {
        getChannelName(id) {
            const channel = this.channelList.find((c) => c.channel_id == id);
            if (channel) {
                return channel.channel_name;
            }
            return "";
        },
        async fetchList() {
            let { page, pageSize } = this;
            let { member_id, channel_id } = this.searchParams;
            let params = {
                page,
                page_size: pageSize,
                member_id,
                channel_id,
            };
            this.tableLoading = true;
            const res = await getCommonConfigList(params);
            this.tableLoading = false;
            if (res.code != 200) return;
            let { list, total } = res.data;
            this.tableData = list || [];
            this.total = parseInt(total);
        },
        addEvent() {
            this.dialogType = "add";
            this.dialogVisible = true;
        },
        editEvent(val) {
            this.currentRow = val;
            this.dialogType = "edit";
            this.dialogVisible = true;
        },
        delEvent({ id }) {
            MessageBox.confirm("您确定要删除该渠道配置？", "提示", {
                type: "warning",
            })
                .then(async () => {
                    this.tableLoading = true;
                    const res = await delCommonConfig({ id });
                    if (res.code == 200) {
                        this.$message.success("删除成功");
                        this.page = 1;
                        this.fetchList();
                    }
                })
                .catch((e) => {
                    this.tableLoading = false;
                    this.$message.error(e.msg);
                });
        },
        resetEvent(params) {
            this.searchParams = params;
        },
        searchEvent(params) {
            this.page = 1;
            this.searchParams = params;
            this.fetchList();
        },
        refreshList() {
            if (this.dialogType === "add") {
                this.page = 1;
                this.fetchList();
            } else {
                this.fetchList();
            }
        },
    },
};
</script>

<style lang="scss">
.detailPopper {
    max-width: 80%;
}
</style>
